import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslations } from '../hooks/use-translations'
import { LocalesEnum } from '../types/locales.enum'

import signalImg from '../assets/images/seo/sgnl/signal.png'
import signaxImg from '../assets/images/seo/sgnx/signax.png'
import { GlobalContext } from '../context/GlobalContext'
import { AppRegionEnum } from '../types/app-region.enum'

interface ISiteData {
  site: {
    siteMetadata: {
      title: string
      author: string
    }
  }
}

interface IMetaObject {
  name: string
  content: string
}
interface ISEOProps {
  description?: string
  image?: string
  keywords?: string
  meta?: IMetaObject[]
  title: string
}

const SEO: React.FC<ISEOProps> = ({
  description = '',
  image,
  keywords,
  meta = [],
  title = 'Signal',
}) => {
  const { locale, t } = useTranslations()
  const { region } = React.useContext(GlobalContext)

  const { site } = useStaticQuery<ISiteData>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
  )

  const metaDescription = description || t('_description')
  const titleTemplate =
    region === AppRegionEnum.Ru
      ? `%s | ${site.siteMetadata.title} - ${region}`
      : `%s - ${site.siteMetadata.title}`
  const metaImage =
    image || (region === AppRegionEnum.Ru ? signalImg : signaxImg)

  return (
    <Helmet
      htmlAttributes={{
        lang: `${locale === LocalesEnum.Ru ? LocalesEnum.Ru : LocalesEnum.En}`,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords ?? t('keywords'),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
